import { Spectrum } from './../_models/spectrum';
import {BehaviorSubject} from 'rxjs';

export const LOAD = 'LOAD';
export const ADD = 'ADD';
export const EDIT = 'EDIT';
export const REMOVE = 'REMOVE';

export class SpectrumStore {
  private spectrums: Spectrum[] = [];
  items$ = new BehaviorSubject<Spectrum[]>([]);

  dispatch(action) {
    console.log('dispatched');
    this.spectrums = this._reduce(this.spectrums, action);
    this.items$.next(this.spectrums);
  }

  _reduce(spectrums: Spectrum[], action) {
    switch (action.type) {
      case LOAD:
        return [...action.data];
      case ADD:
        return [...spectrums, action.data];
      case EDIT:
        return spectrums.map(spectrum => {
          const editedSpectrum = action.data;
          if (spectrum.Id !== editedSpectrum.Id){
            return spectrum;
          }
          return editedSpectrum;
        });
      case REMOVE:
        return spectrums.filter(spectrum => spectrum.Id !== action.data.Id);
      default:
        return spectrums;
    }
  }
}
