import { WorkdaysService } from './../_services/workdays.service';
import { Component, OnInit } from '@angular/core';
import { SpectraldataService } from 'src/app/_services/spectraldata.service';
import { DisplayeddateService } from 'src/app/_services/displayeddate.service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-timenavigation',
  templateUrl: './timenavigation.component.html',
  styleUrls: ['./timenavigation.component.css']
})

export class TimenavigationComponent implements OnInit {
  timeStamp: Date;
  timeDisplay = '...';
  maxDate: {year: number, month: number, day: number} = {year: 2031, month: 12, day: 31};
  minDate: {year: number, month: number, day: number} = {year: 1971, month: 1, day: 1};
  maxEntries = 0;

  datesWithData: Array<{year: number, month: number, day: number, entries: number}> = [];

  constructor(
    private _spectralService: SpectraldataService,
    private displayeddateService: DisplayeddateService,
    private workdayservice: WorkdaysService) { }

  ngOnInit() {
    this.displayeddateService.displayedDateSubject.subscribe( date => {
      this.timeStamp = date;
      this.timeDisplay = this.timeStamp.toLocaleString('de-DE');
      console.log('changed time in timenavigation component', this.timeStamp, this.timeDisplay);
    });
    this.workdayservice.getworkdays().subscribe( dateEntriesObj => {
      dateEntriesObj.list.forEach(date => {
        this.datesWithData.push({ year: date.year, month: date.month, day: date.day, entries: date.entries});
        if ( date.year < this.minDate.year && date.month < this.minDate.month && date.day < this.minDate.day) {
          this.minDate = { year: date.year, month: date.month, day: date.day };
        }
        if ( date.year > this.maxDate.year && date.month > this.maxDate.month && date.day > this.maxDate.day) {
          this.maxDate = { year: date.year, month: date.month, day: date.day };
        }
        this.maxEntries = Math.max (date.entries, this.maxEntries);
      });
    });
  }

  changeTime(rel) {
    let ts = 0;
    if (rel !== 0) {
      ts = Math.round(this.timeStamp.getTime() / 1000);
    }
    this._spectralService.getSpectrums(rel, ts, 1 )
      .subscribe(spectrums => {
        this.displayeddateService.setDisplayDateValue(spectrums[0].time);
        this.timeDisplay = this.timeStamp.toLocaleString('de-DE');
    });
  }
  onDateSelect(date) {
    const newDate = new Date(date.year, date.month - 1, date.day, 12);
    this.displayeddateService.setDisplayDateValue(newDate);
  }

  isWeekend(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  showDiagram(date: NgbDateStruct) {
    if (this.weHaveDataForThisDay(date)) {
      // TODO: get last date/hour/min/sec from datasets
      const newDate = new Date(date.year, date.month, date.day, 12);
      this.displayeddateService.setDisplayDateValue(newDate);
    }
  }

  weHaveDataForThisDay(date: NgbDateStruct): boolean {
    const match = this.datesWithData.find(dataDates =>
      dataDates.year === date.year &&
      dataDates.month === date.month &&
      dataDates.day === date.day);
    if (match) {
      return true;
    }
   return false;
  }
  numberOfEntries(date: NgbDateStruct): number {
    const matchIndex = this.datesWithData.findIndex(function (dataDates) {
      // console.log(dataDates);
      return dataDates.year === date.year &&
      dataDates.month === date.month &&
      dataDates.day === date.day;
    });
    if (matchIndex > -1) {
      const dateObject = this.datesWithData[matchIndex];
      return Math.floor(9 * dateObject.entries / this.maxEntries) + 1;
    } else {
      return 0;
    }
  }
}
