import { SpectrumStore } from './_stores/spectrum.store';
import { SpectrumService } from './_services/spectrum.service';
import { SpectraldataService } from './_services/spectraldata.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
// used to create fake backend
import { fakeBackendProvider } from 'src/app/_helpers/fake-backend';

import { AppComponent } from './app.component';
import { JwtInterceptor } from 'src/app/_helpers/jwt.interceptor';
import { ErrorInterceptor } from 'src/app/_helpers/error.interceptor';
import { SpectrumviewComponent } from './spectrumview/spectrumview.component';
import { HomeviewComponent } from './homeview/homeview.component';
import { SonogramviewComponent } from './sonogramview/sonogramview.component';
import { SonogramComponent } from './sonogramview/sonogram/sonogram.component';
import { SonogramviewtimedComponent } from './sonogramviewtimed/sonogramviewtimed.component';
import { SonogramtimedComponent } from './sonogramviewtimed/sonogramtimed/sonogramtimed.component';
import { MultiviewComponent } from './multiview/multiview.component';
import { TimenavigationComponent } from './timenavigation/timenavigation.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LinediagramComponent } from './linediagram/linediagram.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarComponent } from './calendar/calendar.component';

@NgModule({
  declarations: [
    AppComponent,
    SpectrumviewComponent,
    HomeviewComponent,
    SonogramviewComponent,
    SonogramComponent,
    SonogramviewtimedComponent,
    SonogramtimedComponent,
    MultiviewComponent,
    TimenavigationComponent,
    LoginComponent,
    LinediagramComponent,
    CalendarComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    FormsModule
  ],
  providers: [
    SpectraldataService,
    SpectrumStore,
    SpectrumService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
