import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { SpectraldataService } from '../../_services/spectraldata.service';
import { debounce } from '../../_helpers/debounce';

@Component({
  selector: 'app-sonogram',
  templateUrl: './sonogram.component.html',
  styleUrls: ['./sonogram.component.css']
})

// TODO: integrate timenavigation component

export class SonogramComponent implements OnInit {
  @ViewChild('appSonogramCanvas', { static: true }) chart: ElementRef;

  innerWindowWidth: any;

  ctx: any;

  latestSpectrumDate = Date.now();
  latestSpectrumDateString = 'last spectrum date';

  colorscheme = [
      '#4c434f', '#9e95a3', '#fe6a6a', '#bf00ff',
      '#7e00ff', '#3f00ff', '#0000ff', '#3c78bd',
      '#00d6aa', '#00c3d3', '#7fe700', '#bff300',
      '#ffff00', '#ffcf00', '#ff9f00', '#8e4c00',
      '#ff3e00', '#ff0000', '#ff009d'
  ];

  colorLowerLimit = 20;
  colorUpperLimit = 80;

  canvasHorizontalPadding = 25; // left and right to window border
  sonogramWidth = 800;
  scaleWidth = 50;
  canvasWidth = this.sonogramWidth + this.scaleWidth;
  sonogramHeight = 400;
  scaleHeight = 20;
  canvasHeight = this.sonogramHeight + this.scaleHeight;
  tickLength = 5;
  tickXEvery = 200; // px
  tickYEvery = 20; // %
  freqStep = 0.125;

  datapointsPerSpectrum = 1025;
  spectrumsPerFetch = 300;


  minTime = 999999999999;
  maxTime = 0;
  times = [];
  now = Math.round(Date.now() / 1000);

  minFreq = 0;
  maxFreq = this.datapointsPerSpectrum * this.freqStep + this.minFreq;
  barWidth = this.sonogramWidth / this.spectrumsPerFetch;

  constructor(private _spectralService: SpectraldataService) {}


  @HostListener('window:resize', ['$event'])
  @debounce()
  onResize(event) {
    this.updateSonogramDimensions();
    this.drawSpectrums(Math.round(this.latestSpectrumDate / 1000), 0);
  }

  changeTime(rel) {
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    if ( rel === 0 ) {
      this.drawSpectrums(this.now, 0);
    } else {
      this.drawSpectrums(Math.round(this.latestSpectrumDate / 1000), rel);
      this.latestSpectrumDateString = this.formatDate(this.latestSpectrumDate);
    }
  }

  formatDate (date) {
    return ('0' + date.getDate()).slice(-2) + '.' +
      ('0' + (date.getMonth() + 1)).slice(-2) + '.' +
      date.getFullYear() + ' ' +
      ('0' + date.getHours()).slice(-2) + ':' +
      ('0' + date.getMinutes()).slice(-2);
  }

  colorscale = function ( power ) {
    if (power > this.colorUpperLimit) {
      power = this.colorUpperLimit;
    }
    if (power < this.colorLowerLimit) {
      power = this.colorLowerLimit;
    }
    const powerScaled = (power - this.colorLowerLimit) / (this.colorUpperLimit - this.colorLowerLimit);
    return this.colorscheme[Math.round(powerScaled * (this.colorscheme.length - 1))];
  };
  yScale = function (y) {
    return 0.5 + this.sonogramHeight - (y - this.minFreq) / (this.maxFreq - this.minFreq) * (this.sonogramHeight);
  };
  xScale = function (x) {
    return this.sonogramWidth * x / this.spectrumsPerFetch;
  };

  drawXscale () {
    this.ctx.beginPath();
    this.ctx.moveTo(this.scaleWidth - 1.5 - this.tickLength, this.sonogramHeight + 3.5);
    this.ctx.lineTo(this.canvasWidth - 0.5, this.sonogramHeight + 3.5);
    this.ctx.strokeStyle = '#000000';
    this.ctx.lineWidth = 0.5;
    this.ctx.stroke();
  }
  drawYscale () {
    this.ctx.beginPath();
    this.ctx.moveTo(this.scaleWidth - 1.5, 0);
    this.ctx.lineTo(this.scaleWidth - 1.5, this.sonogramHeight + 3.5 + this.tickLength);
    this.ctx.strokeStyle = '#000000';
    this.ctx.lineWidth = 0.5;
    this.ctx.stroke();
  }

  drawXtick ( xValue, date ) {
    this.ctx.beginPath();
    this.ctx.moveTo(this.scaleWidth - 3.5 + xValue,  this.sonogramHeight + 3.5 );
    this.ctx.lineTo(this.scaleWidth - 3.5 + xValue,  this.sonogramHeight + 3.5 + 3);
    this.ctx.strokeStyle = '#000000';
    this.ctx.lineWidth = 0.5;
    this.ctx.stroke();
    // this.ctx.rotate( Math.PI/2);
    this.ctx.font = 'normal 100 9px "mamma roman"';
    this.ctx.textAlign = 'center';
    this.ctx.fillText( this.formatDate(date), this.scaleWidth - 3.5 + xValue,  this.sonogramHeight + 3.5 + 15);
  }

  drawYtick ( yValue ) {
    this.ctx.beginPath();
    this.ctx.moveTo(this.scaleWidth - 3.5 - this.tickLength,  this.yScale(yValue) + 3 );
    this.ctx.lineTo(this.scaleWidth - 1.5,  this.yScale(yValue) + 3);
    this.ctx.strokeStyle = '#000000';
    this.ctx.lineWidth = 0.5;
    this.ctx.stroke();
    this.ctx.font = 'normal 100 9px "mamma roman"';
    this.ctx.textAlign = 'end';
    this.ctx.fillText( yValue + ' Hz', this.scaleWidth - 3 - this.tickLength,  this.yScale(yValue) + 1);
  }

  drawSpectrums (latestSpectrumTimestamp, fetchRelativeBlockNo) {
    this._spectralService.getSpectrums( this.spectrumsPerFetch * fetchRelativeBlockNo, latestSpectrumTimestamp, this.spectrumsPerFetch)
      .subscribe(spectrums => {
        this.latestSpectrumDate = spectrums[0].time;
        this.latestSpectrumDateString = this.formatDate(this.latestSpectrumDate);
        const drawEvery = Math.floor(this.datapointsPerSpectrum / this.sonogramHeight); // draw every x data point, omit all others
        const drawEveryXAxisTick = Math.ceil( this.tickXEvery / (1.5 * this.barWidth) ); // draw tick every x date
        const drawEveryYAxisTick = Math.floor(this.datapointsPerSpectrum * this.tickYEvery / 100); // draw tick every y data point
        spectrums.forEach( (spectrum, spectrumIndex) => {
          const x0 = this.canvasWidth + 0.5 - this.xScale( spectrumIndex + 1);
          const x1 = x0 + this.barWidth;
          if (spectrumIndex % drawEveryXAxisTick === 0) {
            this.drawXtick ( x0 + this.barWidth / 2, spectrum.time );
          }
          let maxNoiseOfEvery = 0;
          spectrum.data.forEach( (spectrumData, spectrumDataIndex) => {
            maxNoiseOfEvery = Math.max(maxNoiseOfEvery, spectrumData.noiseLevel);
            if (spectrumIndex === 0 && spectrumDataIndex % drawEveryYAxisTick === 0) {
              this.drawYtick (spectrumData.freq);
            }
            if (spectrumDataIndex % drawEvery === 0) { // only 1/drawEvery noise values (y-axis)
              const y0 = this.yScale(spectrumData.freq);
              this.ctx.beginPath();
              this.ctx.moveTo(x0, y0);
              this.ctx.lineTo(x1, y0);
              this.ctx.strokeStyle = this.colorscale(Math.round(100 * maxNoiseOfEvery) / 100);
              this.ctx.lineWidth = drawEvery;
              this.ctx.stroke();
              maxNoiseOfEvery = 0;
            }
          });
        });
        this.drawYscale();
        this.drawXscale();
      }
    );
  }

  updateSonogramDimensions() {
    this.innerWindowWidth = window.innerWidth;
    this.sonogramWidth = this.innerWindowWidth - 2 * this.canvasHorizontalPadding - this.scaleWidth;
    this.barWidth = this.sonogramWidth / this.spectrumsPerFetch;
    console.log('window width ', this.innerWindowWidth);
    this.canvasWidth = this.sonogramWidth + this.scaleWidth;
    this.canvasHeight = this.sonogramHeight + this.scaleHeight;
  }

  ngOnInit () {
    console.log('sonogram-component init');
    this.ctx = this.chart.nativeElement.getContext('2d');
    this.updateSonogramDimensions();
    this.drawSpectrums(Math.round(Date.now() / 1000), 0);
  }
  onDateSelect(foo) {}
}

