import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DisplayeddateService {
    public displayedDateSubject: BehaviorSubject<Date>;

    constructor() {
      this.displayedDateSubject = new BehaviorSubject<Date>( new Date() );
    }

    public setDisplayDateValue( date: Date ): Date {
      this.displayedDateSubject.next( date );
      console.log('displayDate set in displayedDate service', date);
      return date;
    }
}
