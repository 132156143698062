import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sonogramview',
  templateUrl: './sonogramview.component.html',
  styleUrls: ['./sonogramview.component.css']
})
export class SonogramviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('sonogramview-component');
  }
}
