import { WorkdaysService } from './../_services/workdays.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DisplayeddateService } from 'src/app/_services/displayeddate.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  @ViewChild('dp', {static: false}) datepicker: NgbCalendar;
  model: NgbDateStruct;
  maxDate: {year: number, month: number, day: number};
  minDate: {year: number, month: number, day: number};
  // calDate: {year: number, month: number, day: number};
  // entriesPerDate: {year: number, month: number, day: number, entries: number};
  maxEntries = 0;

  datesWithData: Array<{year: number, month: number, day: number, entries: number}> = [];

  now = new Date();
  constructor(private calendar: NgbCalendar,
    private displayeddateService: DisplayeddateService,
    private router: Router,
    private workdayservice: WorkdaysService) { }

  // https://stackoverflow.com/questions/45522300/ng-bootstrap-datepicker-how-to-highlight-specific-days-in-angular4
  ngOnInit() {
    const dateEntries = [];

    this.minDate = {year: 1971, month: 1, day: 1};
    this.maxDate = {year: 2031, month: 12, day: 31};
    this.workdayservice.getworkdays().subscribe( dateEntriesObj => {
      // console.log('dateEntriesObj',dateEntriesObj);
      dateEntriesObj.list.forEach(date => {
        dateEntries.push({ year: date.year, month: date.month, day: date.day, entries: date.entries});
        this.maxEntries = Math.max (date.entries, this.maxEntries);
      });
      this.minDate = dateEntriesObj.min;
      this.maxDate = dateEntriesObj.max;
      this.datesWithData = dateEntries;
    });
  }
  /*
  selectToday() {
    this.model = this.calendar.getToday();
  }
  */
  selectToday() {
    this.model = {
      year: this.now.getFullYear(),
      month: this.now.getMonth() + 1,
      day: this.now.getDate(),
    };
  }

  isWeekend(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  isDisabled(date: NgbDateStruct, current: {month: number}) {
    return date.month !== current.month;
  }

  hasTask(date: NgbDateStruct) {
    return this.weHaveDataForThisDay(date);
  }

  showDiagram(date: NgbDateStruct) {
    if (this.weHaveDataForThisDay(date)) {
      // TODO: get last date/hour/min/sec from datasets
      const newDate = new Date(date.year, date.month, date.day, 12);
      this.displayeddateService.setDisplayDateValue(newDate);
      this.router.navigate(['/spectrum']);
    }
  }

  weHaveDataForThisDay(date: NgbDateStruct): boolean {
    // console.log(date);
    const match = this.datesWithData.find(dataDates =>
      dataDates.year === date.year &&
      dataDates.month === date.month &&
      dataDates.day === date.day);
    if (match) {
      return true;
    }
   return false;
  }

  numberOfEntries(date: NgbDateStruct): number {
    const matchIndex = this.datesWithData.findIndex(function (dataDates) {
      // console.log(dataDates);
      return dataDates.year === date.year &&
      dataDates.month === date.month &&
      dataDates.day === date.day;
    });
    if (matchIndex > -1) {
      const dateObject = this.datesWithData[matchIndex];
      return Math.floor(9 * dateObject.entries / this.maxEntries) + 1;
    } else {
      return 0;
    }
  }

}
