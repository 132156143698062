import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogkanalService {
  logischerKanal = 0;
  customer = '';
  diagramType = '';
  constructor() { }
  getLogischerKanal() {
    return this.logischerKanal;
  }
  setLogischerKanal(kanalId) {
    this.logischerKanal = kanalId;
  }
  getCustomer() {
    return this.customer;
  }
  setCustomer(customer) {
    this.customer = customer;
  }
  getDiagramType() {
    return this.diagramType;
  }
  setDiagramType(diagramType) {
    this.diagramType = diagramType;
  }
}
