export class Spectrum {
  Id: number;
  Tbllogischerkanal_id: number;
  Zeitstempel: Date;
  AnzahlLinien: number;
  Linienbreite: number;
  Daten: SpectrumData[];
  Ldz: number;
  RMSa: number;
  RMSv: number;
  RMSd: number;
  TmsBewertet: number;
  zsmillis: string;
}

export class SpectrumRaw {
  Id: number;
  Tbllogischerkanal_id: number;
  Zeitstempel: number;
  AnzahlLinien: number;
  Linienbreite: number;
  Daten: string;
  Ldz: number;
  RMSa: number;
  RMSv: number;
  RMSd: number;
  TmsBewertet: number;
  zsmillis: string;
}

export class SpectrumData {
  frequency: number;
  amplitude: number;
}

export class SpectrumResult {
  list: SpectrumRaw[];
}
