import { Chartjsconfig } from 'src/app/_models/chartjsconfig';
import { Component, ViewChild, Input, ElementRef, OnChanges, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-linediagram',
  templateUrl: './linediagram.component.html',
  styleUrls: ['./linediagram.component.css']
})
export class LinediagramComponent implements OnChanges {
  @ViewChild('spectrumCanvas', { static: true }) chart: ElementRef;
  @Input() time: any;
  @Input() dataset: any;
  @Input() labels: any;
  @Input() xLabel: String;
  @Input() yLabel: String;
  @Input() diagramHeight: String;
  @Input() showXScale: boolean;
  @Input() showXScaleLabels: boolean;

  ctx: any;
  spectrumChart: any;
  config: Chartjsconfig;

  constructor() {

  }

  ngOnChanges() {
    if ( this.spectrumChart) {
      // console.log('this.dataset', this.dataset);
      this.config.data.datasets[0].data = this.dataset;
      console.log('chartj-config:', this.config);
      // this.config.data.labels = this.labels;
      this.spectrumChart.update();
    } else {
      this.drawCanvas();
    }
  }

  drawCanvas () {
    this.ctx = this.chart.nativeElement.getContext('2d');
    const XScaleLabels = this.showXScaleLabels;
    if (!this.xLabel) {
      this.xLabel = '';
    }
    if (!this.yLabel) {
      this.yLabel = '';
    }
    this.config = {
      type: 'line',
      data: {
        // labels: [],
        datasets: [
          {
            data: [],
            borderColor: '#9cba9f',
            fill: false,
            label: 'amplitude',
            radius: 2
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 80
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: this.showXScale,
            type: 'linear',
            scaleLabel: {
              display: true,
              labelString: this.xLabel
            },
            bounds: 'data',
            ticks: {
              major: {
                fontStyle: 'bold',
                fontColor: '#FF0000'
              },
              autoSkip: true,
              autoSkipPadding: 100,
              callback: function(value, index, values) {
                if (XScaleLabels === true) {
                  return value;
                } else {
                  return '';
                }
              }
            }
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.yLabel
            }
          }],
        },
        tooltips: {
          callbacks: {
            title: (title) => 'frequency: ' + title[0].label
          }
        }
      }
    };
    console.log('1st config', this.config);
    this.spectrumChart = new Chart(this.ctx, this.config);
  }
}
