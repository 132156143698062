import { Observable } from 'rxjs';
import { SpectrumService } from './../_services/spectrum.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DisplayeddateService } from 'src/app/_services/displayeddate.service';
import { SpectraldataService } from 'src/app/_services/spectraldata.service';
import { Spectrum, SpectrumData } from 'src/app/_models/spectrum';

@Component({
  selector: 'app-spectrumview',
  templateUrl: './spectrumview.component.html',
  styleUrls: ['./spectrumview.component.css']
})
export class SpectrumviewComponent implements OnInit {
  timeDate: Date;
  timeDisplay = '...';
  dataset: any;
  labels: any;
  spectrums$: Observable<Spectrum[]>;

  constructor(
    private spectrumService: SpectrumService
  ) { }

  ngOnInit() {
    this.spectrums$ = this.spectrumService.findSpectrums();
    this.spectrums$.subscribe( spectrums => {
      if (spectrums.length) {
        const res: SpectrumData[] = spectrums[0].Daten;

        this.dataset = [];
        console.log('len', spectrums.length);
        res.forEach(data => {
          this.dataset.push({
            x: data.frequency,
            y: data.amplitude
          });
        });

        /*
        const noiseLevels = res.map(result => result.amplitude);
        const frequencies = res.map(result => result.frequency);
        const times = [];
        frequencies.forEach((resTime) => {
          times.push(resTime);
        });
        this.dataset = noiseLevels;
        this.labels = frequencies;
        */
        console.log('this.dataset:::', this.dataset);
      }
    });
  }
}
