import { LogkanalService } from './_services/logkanal.service';
import { Component, ElementRef } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  logischerKanal = 0;
  customer = '';
  diagramType = '';

  constructor( private logKanalService: LogkanalService, private elementRef:ElementRef) {
    this.logischerKanal = +this.elementRef.nativeElement.getAttribute('lkid'); // casts to number
    // console.log("lkid", lkid)
    logKanalService.setLogischerKanal(this.logischerKanal);
    this.customer = this.elementRef.nativeElement.getAttribute('customer');
    logKanalService.setCustomer(this.customer);
    this.diagramType = this.elementRef.nativeElement.getAttribute('type');
    logKanalService.setDiagramType(this.diagramType);
  }
}
