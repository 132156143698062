import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class WorkdaysService {
  private httpOptions = {
    headers: new HttpHeaders({
    })
  };
  constructor(private http: HttpClient) { }

  getworkdays(): any {
    return this.http.get( `${environment.apiUrl}` + '?wd=any', this.httpOptions )
    .pipe(
      map( function(result: any) { // TODO: Interface
        // console.log('result', result);
        return result;
      })
    );
  }
  getworkday(date: NgbDateStruct): any {
    return this.http.get( `${environment.apiUrl}` + '?wd=' +
    date.year + '-' + date.month + '-' + date.day, this.httpOptions )
    .pipe(
      map( function(result: any) { // TODO: Interface
        // console.log('result.list', result.list);
        return result;
      })
    );
  }
}
