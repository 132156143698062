import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DisplayeddateService } from 'src/app/_services/displayeddate.service';
import { SpectraldataService } from 'src/app/_services/spectraldata.service';
import { Chartjsdataset } from 'src/app/_models/chartjsconfig';

@Component({
  selector: 'app-multiview',
  templateUrl: './multiview.component.html',
  styleUrls: ['./multiview.component.css']
})
export class MultiviewComponent implements OnInit {
  timeStamp: Date;
  timeDisplay = '...';
  labels: any[];
  dataset: Chartjsdataset;

  constructor(
    private displayeddateService: DisplayeddateService,
    private spectraldataService: SpectraldataService
  ) { }

  ngOnInit() {
    this.displayeddateService.displayedDateSubject.subscribe( date => {
      this.timeStamp = date;
      this.timeDisplay = this.timeStamp.toLocaleString('de-DE');
      // console.log('changed time in timenavigation component', this.timeDisplay);
    });
    this.spectraldataService.getSpectrums(0, 0, 0)
      .subscribe(spectrums => {
        const res: any = spectrums[0].data; // TODO: Interface
        const noiseLevels = res.map(result => result.noiseLevel);
        const frequencies = res.map(result => result.freq);
        const times = [];
        frequencies.forEach((res) => {
          times.push(res);
        });
        this.dataset = noiseLevels;
        this.labels = frequencies;
    });
  }
  ngAfterViewInit () {
    this.displayeddateService.displayedDateSubject.subscribe( date => {
      this.timeStamp = date;
      this.spectraldataService.getSpectrums(0, Math.round(this.timeStamp.getTime() / 1000), 1)
      .subscribe(spectrums => {
        const res: any = spectrums[0].data; // TODO: Interface
        const noiseLevels = res.map(result => result.noiseLevel);
        const frequencies = res.map(result => result.freq);
        const times = [];
        frequencies.forEach((res) => {
          times.push(res);
        });
        this.dataset = noiseLevels;
        this.labels = frequencies;
      });
    });
  }
}
